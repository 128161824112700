.fixed-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.error{
  color:red
}

.slides-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide {
  flex: 0 0 100%;
  transition: transform 0.5s ease-in-out;
}

.active {
  transform: translateX(0);
}


 

 #rcc-confirm-button{
  background: rgb(239, 241, 242);
  border: 0px;
  border-radius: 0px;
  box-shadow: none;
  color: rgb(1, 36, 77);
  cursor: pointer;
  flex: 0 0 auto;
  padding: 5px 10px;
  margin: 15px;
  font-size: 13px;
 }
 

 
