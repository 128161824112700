 
  
  .slides-container {
    display: flex;
    transition: transform 0.8s ease; /* Add smooth transition effect */
  }
  
  .slide {
    flex: 0 0 100%; /* Ensure each slide takes up the full width */
    overflow: hidden; 
    position: relative; /* Needed for absolute positioning of overlay content */
  }
  
  .img-fluid {
    width: 100%; /* Make the image fill the entire slide */
    /* height: auto; Maintain aspect ratio */
    transition: transform 0.8s ease; /* Add smooth transition effect */
  }
  
  .slide:hover .img-fluid {
    transform: scale(1.1); /* Scale up the image on hover */
  }
  
  .overlaycontent {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0; /* Initially hide overlay content */
    transition: opacity 0.8s ease; /* Add smooth transition effect */
  }
  
  .slide .overlaycontent {
    opacity: 1; /* Show overlay content on hover */
  }
  
  /* Adjust other styles as needed */
  