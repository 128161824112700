@font-face {
  font-family: 'HyundaiRegular';
  src: url("fonts/HyundaiSansHead-Regular.eot");
  src: url("fonts/HyundaiSansHead-Regular.eot?#iefix") format("embedded-opentype"), url("fonts/HyundaiSansHead-Regular.woff") format("woff"), url("fonts/HyundaiSansHead-Regular.woff2") format("woff2"), url("fonts/HyundaiSansHead-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'HyundaiLight';
  src: url("fonts/HyundaiSansHead-Light.eot");
  src: url("fonts/HyundaiSansHead-Light.eot?#iefix") format("embedded-opentype"), url("fonts/HyundaiSansHead-Light.woff") format("woff"), url("fonts/HyundaiSansHead-Light.woff2") format("woff2"), url("fonts/HyundaiSansHead-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HyundaiBold';
  src: url("fonts/HyundaiSansHead-Bold.eot");
  src: url("fonts/HyundaiSansHead-Bold.eot?#iefix") format("embedded-opentype"), url("fonts/HyundaiSansHead-Bold.woff") format("woff"), url("fonts/HyundaiSansHead-Bold.woff2") format("woff2"), url("fonts/HyundaiSansHead-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: "HyundaiLight";
  font-weight: 300;
}

b,
strong {
  font-weight: bold;
}

:root {
  scroll-behavior: smooth;
  --bs-border-width: 1px;
  --bs-body-bg: #fff;
  --bs-body-color-rgb: 33, 37, 41;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*,
::before,
::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

a,
a:hover,
a:focus {
  text-decoration: none !important;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  cursor: pointer !important;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "HyundaiBold";
}

p {
  color: #000;
  line-height: 1.5;
  font-size: 18px;
  font-family: "HyundaiLight";
  font-weight: 300;
}

ol li,
ul li {
  color: #000;
  line-height: 1.5;
  font-size: 18px;
  font-family: "HyundaiLight";
  font-weight: 300;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.bg-light-success {
  background-color: rgb(212 238 227) !important;
}

.bg-light-warning {
  background-color: rgb(255 243 205) !important;
}

.rounded-3 {
  border-radius: 0.5rem !important;
  -webkit-border-radius: 0.5rem !important;
  -moz-border-radius: 0.5rem !important;
  -ms-border-radius: 0.5rem !important;
  -o-border-radius: 0.5rem !important;
}

.vr {
  display: inline-block;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.1;
}

.top-50 {
  top: 50% !important;
}

.end-0 {
  right: 0 !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
  -webkit-transform: translateY(-50%) !important;
  -moz-transform: translateY(-50%) !important;
  -ms-transform: translateY(-50%) !important;
  -o-transform: translateY(-50%) !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.full-width {
  width: 100%;
}

.color-facebook {
  color: #3b5998 !important;
}

.color-whatsapp {
  color: #4caf50 !important;
}

.color-linkedin {
  color: #1685b9 !important;
}

.color-twitter {
  color: #00aced !important;
}

.text-primary {
  color: #002c5f !important;
}

.btn {
  color: #fff !important;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 0;
  font-weight: 500;
  font-family: HyundaiRegular;
  background: #002c5f;
  border: 3px solid #002c5f;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.btn:hover {
  color: #ffffff;
  background: #01244d;
  border-color: #002c5f;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:not(.btn-check)+.btn.btnheader:active,
:not(.btn-check)+.btn.btnheader:active:focus-visible {
  color: #fff;
  background: #002c5f;
  border: 3px solid #002c5f;
}

.btnheader {
  position: relative;
  color: #fff;
  font-size: 18px;
  line-height: 1.5;
  padding: 6px 20px 6px;
  border: 0;
  border-radius: 0;
  background: #002c5f;
  border: 3px solid #002c5f;
  transition: 0.3s linear;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
}

.btnheader:hover {
  color: #ffffff;
  background: #01244d;
  border-color: #002c5f;
}

.menu-bar {
  background: #ffffff !important;
  background-color: #ffffff !important;
  -webkit-box-shadow: 0 0px 3rem 0.425rem rgba(33, 37, 41, 0.1);
  box-shadow: 0 0px 3rem 0.425rem rgba(33, 37, 41, 0.1);
}

.menu-bar .navbar-brand img {
  /* width: 80%; */
  width: 149px;
  height: 22px;
}

.menu-bar .dropdown:hover>.dropdown-menu {
  display: block;
}

.menu-bar .navbar-nav .nav-item {
  margin-right: 15px;
  padding-right: 20px;
  padding-left: 20px;
}

.menu-bar .nav-item .nav-link {
  color: #000;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  position: relative;
  padding: 0;
  font-family: "HyundaiRegular";
  display: inline-block;
}

/* .menu-bar .nav-item .nav-link::before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 0;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #002c5f;
  transition: opacity 0.3s ease, width 0.3s ease;
  -ms-transition: opacity 0.3s ease, width 0.3s ease;
  -webkit-transition: opacity 0.3s ease, width 0.3s ease;
  -moz-transition: opacity 0.3s ease, width 0.3s ease;
  -o-transition: opacity 0.3s ease, width 0.3s ease;
}

.menu-bar .nav-item:hover .nav-link::before {
  width: 100%;
} */

.menu-bar .nav-item .nav-link.dropdown-toggle::after {
  position: relative;
  top: 1px;
}

.carousel-item>img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  max-width: 100%;
}

.carousel-item,
.carousel-item>img {
  height: 15rem;
}

.carousel.carouselmain {
  position: relative;
  margin: 0;
  padding: 0;
  padding-top: 5.225rem;
}

.carouselmain .carousel-control-next,
.carouselmain .carousel-control-prev {
  width: 5%;
}

.carouselmain .carousel-caption {
  right: 8%;
  left: 8%;
  top: 10rem;
  z-index: 10;
}

.carouselmain .carousel-control-next .carousel-control-next-icon,
.carouselmain .carousel-control-prev .carousel-control-prev-icon {
  font-size: 30px;
  background-image: none !important;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  transition: 0.3s;
  color: rgba(255, 255, 255, 0.6);
  width: 54px;
  height: 54px;
  line-height: 1.5;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.carouselmain .carousel-item,
.carouselmain .carousel-item>img {
  height: 82vh;
  -o-object-fit: cover;
  object-fit: cover;
  background-color: #777;
}

.carouselmain .containerbox {
  position: relative;
  max-width: 96%;
  width: 100%;
  margin: 0 auto 0;
  overflow: hidden;
}

.carouselmain .slide-inner {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  color: #fff;
  z-index: 1;
  transition: 0.25s ease-in-out;
  /* background: rgba(0, 0, 0, .2); */
  background-image: -o-linear-gradient(left, #00000059 15%, transparent 100%) !important;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(15%, #00000059), to(transparent)) !important;
  background-image: linear-gradient(to right, #00000059 15%, transparent 100%) !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-right: 33%;
  padding-left: 100px;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
}

.carouselmain .slide-inner h1 {
  color: #fff;
  font-size: 46px;
  line-height: 58px;
  font-weight: 500;
}

.carouselmain .slide-inner h2 {
  color: #fff;
  font-size: 34px;
  line-height: 48px;
  font-family: "HyundaiRegular";
  font-weight: 500;
  margin: -10px 0 -6px;
}

.carouselmain .slide-inner h3 {
  color: #FFF;
  font-size: 26px;
  font-family: "HyundaiRegular";
  line-height: 34px;
  font-weight: 400;
  margin: 2px 0 0.5rem;
}

.carouselmain .slide-inner p {
  color: #ffffff;
  font-size: 28px;
  font-family: "HyundaiRegular";
  font-weight: bold;
}

.carouselmain .containerbox .carousel-item video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btn-primary {
  position: relative;
  color: #fff;
  font-size: 18px;
  font-family: "HyundaiRegular";
  line-height: 1.5;
  padding: 6px 22px 6px;
  border: 0;
  border-radius: 0;
  background: #002c5f;
  border: 3px solid #002c5f;
  transition: 0.3s linear;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
}

.btn-primary:hover {
  color: #ffffff;
  background: #01244d !important;
  border-color: #002c5f !important;
}

.carouselmain .containerbox .btn-primary {
  min-width: 185px;
  font-size: 20px;
}

.innerbannerHeader {
  position: relative;
  background: #000;
  background-size: cover !important;
  background-position: center !important;
  z-index: 99;
  padding-top: 3.906rem;
  width: 100%;
  min-height: 440px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
}

/* .innerbannerHeader::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-image: -o-linear-gradient(top, rgb(0 0 0/0%), rgb(22 22 22/15%), rgb(12 12 12/0%));
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgb(0 0 0/0%)), color-stop(rgb(22 22 22/15%)), to(rgb(12 12 12/0%)));
  background-image: linear-gradient(to bottom, rgb(0 0 0/0%), rgb(22 22 22/15%), rgb(12 12 12/0%));
  z-index: 1;
} */

.innerbannerHeader .img-fliuid,
.innerbannerHeader .img-fluid {
  width: 100% !important;
  max-width: 100%;
  height: auto;
}

.innerbannerHeader .bannercontainer {
  width: 100%;
  height: 100%;
  padding-top: 62px;
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-image: -o-linear-gradient(left, #00000059 15%, transparent 100%) !important;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(15%, #00000059), to(transparent)) !important;
  background-image: linear-gradient(to right, #00000059 15%, transparent 100%) !important;
}

.innerbannerHeader .bannercontainer .container {
  width: 100%;
  height: 100%;
}

.innerbannerHeader .bannercontainer .bannerTitle {
  margin-left: 0 !important;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: self-start;
  -ms-flex-align: self-start;
  align-items: self-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  transform: translateY(8rem);
  -webkit-transform: translateY(8rem);
  -moz-transform: translateY(8rem);
  -ms-transform: translateY(8rem);
  -o-transform: translateY(8rem);
}

.innerbannerHeader h1 {
  color: #fff;
  font-size: 46px;
  line-height: 58px;
  margin: 0;
  font-weight: 500;
  font-family: "HyundaiBold";
  /* text-shadow: 2px 2px 8px #0a0a0a73; */
}

.innerbannerHeader h2 {
  color: #ffffff;
  font-size: 34px;
  line-height: 48px;
  font-weight: 500;
  margin-top: -10px;
}

.innerbannerHeader .bannercontainer .bannerTitle h2 {
  color: #ffffff;
  font-size: 34px;
  line-height: 48px;
  font-weight: 500;
  margin-top: -10px;
}

.footermain {
  text-align: center;
  padding: 30px 0 40px 0;
  font-size: 16px;
  font-weight: 500;
  background: #1c1b1b;
}

.footermain a,
.footermain span {
  color: #999;
  font-size: 14px;
}

.footermain .footerlogo {
  filter: brightness(0) invert(1);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -o-object-fit: contain;
  object-fit: contain;
  -webkit-filter: brightness(0) invert(1);
  margin-bottom: 30px;
}

.footermain .footerlink {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footermain .footerlink a {
  position: relative;
  margin-right: 30px;
  margin-bottom: 5px;
}

.footermain .footerlink a::before {
  content: "";
  position: absolute;
  right: -15px;
  height: 14px;
  width: 1px;
  top: 3px;
  background: #9999996e;
}

.footermain .footerlink a:last-child::before {
  display: none;
}

.footermain .enqiryfooter span {
  color: #5f5f5f;
  font-size: 12px;
}

.footermain .footersocial {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 5px;
}

.footermain .footersocial a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.footermain .footersocial svg {
  height: 18px;
  width: 18px;
  margin-right: 15px;
}

.calltoactionbox {
  position: relative;
  padding: 48px 0 48px;
  background: #f8f8f8 !important;
}

.calltoactionbox h2 {
  color: #002b5f;
  font-size: 26px;
  margin-bottom: 8px;
  font-weight: normal;
}

.calltoactionbox h4 {
  color: #002b5f;
  font-size: 38px;
  margin-top: 20px;
  font-weight: bold;
}

.calltoactionbox .btn-primary {
  font-size: 20px;
}

.secTitle {
  position: relative;
  text-align: center;
  width: 100%;
  padding-bottom: 20px;
  margin: 0 auto;
}

.secTitle h2 {
  color: #000000;
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  position: relative;
  padding-bottom: 10px;
  line-height: 42px;
}

.secTitle h2::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  width: 30%;
  height: 3px;
  background: #1f3766;
  margin: 0 auto;
}

.blueband {
  position: relative;
  padding: 30px 0 18px;
  background: #002c5f;
}

.blueband .h5,
.blueband .h3 {
  color: #ffffff;
  margin-bottom: 2px;
}

.blueband .h5 {
  font-size: 18px;
  font-weight: 400;
}

.blueband .h3 {
  margin-top: 8px;
  font-size: 28px;
  line-height: 32px;
  font-family: "HyundaiRegular";
  font-weight: normal;
}

.happeningbox {
  position: relative;
  text-align: center;
  overflow: hidden;
}

.happeningbox img {
  width: 100%;
  max-width: 100%;
  height: 360px;
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.happeningbox .overlaycontent {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  color: #ffffff;
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  justify-content: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  background: rgba(0, 0, 0, 0.2);
  background: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .65) 90%, rgba(0, 0, 0, .65) 96%);
  background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .65) 90%, rgba(0, 0, 0, .65) 96%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .65) 90%, rgba(0, 0, 0, .65) 96%);
}

.happeningbox .overlaycontent h2 {
  position: relative;
  color: #fff;
  font-size: 26px;
  line-height: 36px;
  margin-bottom: 30px;
  text-align: left;
  position: absolute;
  top: 40%;
}

.happeningbox .overlaycontent h2::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  width: 100px;
  height: 3px;
  background: #FFFFFF;
  margin: 0;
}

.happeningbox:hover img {
  opacity: 0.8;
  transform: scale(1.05, 1.05);
  -webkit-transform: scale(1.05, 1.05);
  -moz-transform: scale(1.05, 1.05);
  -ms-transform: scale(1.05, 1.05);
  -o-transform: scale(1.05, 1.05);
}

.countdownbox {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.countdownbox ul {
  margin: 0;
  padding: 0;
  padding: 10px 0 10px;
  list-style: none;
}

.countdownbox ul li {
  display: inline-block;
  list-style-type: none;
  padding: 0 22px;
  color: #767676;
  font-size: 22px;
  line-height: 1.2;
  font-family: "HyundaiLight";
  font-weight: 600;
}

.countdownbox li span {
  display: block;
  color: #000000;
  font-size: 54px;
  line-height: 56px;
  font-family: "HyundaiRegular";
  font-weight: bold;
  margin-bottom: 4px;
}

.countdownbox h2 {
  text-align: center;
  font-weight: bold;
  color: #000;
  font-size: 42px;
  line-height: 1.3;
  margin-bottom: 15px;
}

.tabpillsbox .nav-pills .nav-item {
  margin-right: 15px;
}

.tabpillsbox .nav-pills .nav-item:last-child {
  margin-right: 0;
}

.tabpillsbox .nav-pills .nav-link {
  color: #243255;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  text-align: center;
  padding: 10px 15px;
  min-width: 130px;
  border: #243255 solid 2px;
  border-radius: 0;
  transition: 0.3s all;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
}

.tabpillsbox .nav-pills .nav-link.active,
.tabpillsbox .nav-pills .show>.nav-link {
  color: #ffffff;
  background-color: #002c5f;
}

.tabpillsbox .nav-pills .nav-link:hover {
  color: #ffffff;
  background: #01244d;
  border-color: #002c5f;
}

.adventurebox {
  position: relative;
  background: #002c5f;
  color: #ffffff;
  margin-top: 20px;
  padding: 15px 10px 10px;
}

.adventurebox p {
  color: #ffffff;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 4px;
}

.eventsmainbx {
  position: relative;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #fff;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.eventsmainbx figure {
  position: relative;
  overflow: hidden;
  margin: 0;
  width: 100%;
  height: 100%;
}

.col-sm-4.mb-4 .eventsmainbx figure {
  min-height: 240px;
  height: 240px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.eventsmainbx figure img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: all 1s;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
}

.eventsmainbx:hover figure img {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.eventsmainbx figcaption {
  position: relative;
  height: auto;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 10px 6px 10px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.eventsmainbx figcaption .knowmorebtn {
  color: #364466;
  font-size: 16px;
  line-height: 29px;
  font-weight: 400;
  font-family: "HyundaiRegular";
}

.eventsmainbx figcaption .knowmorebtn .fa {
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.eventsmainbx figcaption .knowmorebtn:hover .fa {
  transform: translateX(3px);
  -moz-transform: translateX(3px);
  -ms-transform: translateX(3px);
  -o-transform: translateX(3px);
  -webkit-transform: translateX(3px);
}

.eventsmainbx figcaption .datebx {
  position: relative;
  background: #dadada;
  color: #363636;
  font-size: 14px;
  line-height: 1.5;
  padding: 8px 10px 6px;
  margin: 8px 0 16px 0;
}

.eventsmainbx figcaption .datebx .fa {
  font-size: 16px;
  margin-right: 6px;
}

.eventsmainbx figcaption .datebx span p {
  color: #363636;
  font-size: 14px;
  margin-bottom: 0;
}

.eventsmainbx figcaption h2 {
  color: #243255;
  font-size: 19px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 8px;
}

.eventsmainbx figcaption p {
  color: #364466;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: "HyundaiRegular";
}

.eventsmainbx figcaption p .fa {
  color: #676768;
  margin-right: 8px;
}

.eventsmainbx figcaption .btn-primary {
  padding: 4px 15px 4px;
}

.eventsmainbx figcaption.gallerybox {
  padding-bottom: 22px;
}

.eventsmainbx figcaption.gallerybox h2 {
  font-size: 20px;
}

.eventsmainbx figcaption.gallerybox .gallerybtn {
  color: #364466;
  font-size: 16px;
  font-family: "HyundaiRegular";
  font-weight: 500;
}

.eventsmainbx figcaption.gallerybox .gallerybtn span {
  position: relative;
  padding: 0 10px 6px;
  display: inline-block;
  border-bottom: #002c5f solid 2px;
  transition: all 1s;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
}

.eventsmainbx figcaption.gallerybox .gallerybtn:hover span {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}

.carouselvideobox {
  position: relative;
  width: 100%;
  margin: 0 auto 0;
  padding: 0 16px;
}

.carouselvideobox .carousel-item,
.carouselvideobox .carousel-item>img {
  height: 290px;
  background: #777;
}

.carouselvideobox .carousel-control-next,
.carouselvideobox .carousel-control-prev {
  width: 12%;
  opacity: 1;
}

.carouselvideobox .carousel-control-next {
  right: -1.825rem;
}

.carouselvideobox .carousel-control-prev {
  left: -1.825rem;
}

.carouselvideobox .carousel-control-next .carousel-control-next-icon,
.carouselvideobox .carousel-control-prev .carousel-control-prev-icon {
  font-size: 18px;
  line-height: 1.5;
  border: #ffffff solid 1px;
  background-image: none !important;
  background-color: #000000;
  border-radius: 50px;
  transition: 0.3s;
  color: #ffffff;
  width: 42px;
  height: 42px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.carouselvideobox .carousel-indicators {
  margin-bottom: 0;
}

.testimonial-item {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  position: relative;
  background: #fff;
}

.testimonial-item .testithumb {
  width: 65px;
  height: 65px;
  margin-right: 15px;
}

.testimonial-item h3 {
  font-size: 21px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #1f3766;
}

.testimonial-item h4 {
  font-size: 14px;
  color: #7d7d7d;
  margin: 0;
}

.testimonial-item p {
  color: #7a7a7a;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  margin-bottom: 16px;
}

.homefixbtn {
  border-radius: 0px;
  height: auto;
  color: #fff;
  padding: 5px 15px;
  position: fixed;
  right: 0px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 1;
  visibility: visible;
  z-index: 9999;
  bottom: 10%;
  height: 50px;
  width: 50px;
  background: rgb(0, 44, 95);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  -moz-transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  -ms-transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  -o-transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

.homefixbtn .fa,
.homefixbtn .fa-solid {
  font-size: 24px;
  color: #fff;
  transition: .3s;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -ms-transition: .3s;
  -o-transition: .3s;
}

.homefixbtn:hover {
  color: #ffffff;
  text-decoration: none !important;
}

.homefixbtn:hover .fa,
.homefixbtn:hover .fa-solid {
  transform: translateY(-8px);
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  -ms-transform: translateY(-8px);
  -o-transform: translateY(-8px);
}

.bread-crumb {
  position: relative;
  background: #002c5f;
}

.bread-crumb::before {
  background: #e5e5e5;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20%;
  height: 100%;
}

.bread-crumb .breadcrumbbox {
  width: 100%;
  padding: 18px 15px 18px;
  position: relative;
  text-align: center;
  background: #002c5f;
  display: block;
  color: #fff;
  font-size: 20px;
  line-height: 1.5;
  margin: 0;
  text-align: center;
  font-family: "HyundaiRegular";
  font-weight: normal;
}

.bread-crumb .grayband {
  background: #e5e5e5;
  color: #002c5f;
}

.locationtimebox {
  position: relative;
  padding: 2px 0 2px;
  text-align: center;
}

.locationtimebox .btn-new {
  width: 100%;
  display: block;
  font-size: 18px;
  line-height: 1.5;
  padding: 10px 10px;
  font-family: "HyundaiRegular";
  font-weight: normal;
}

.btn_div {
  color: #002c5f !important;
  font-size: 18px;
  line-height: 1.5;
  border-radius: 0;
  font-weight: 700;
  font-family: HyundaiRegular;
  background: #e5e5e5;
  border: 3px solid #e5e5e5;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}
.locationtimebox .btn-new_div {
  width: 100%;
  display: block;
  font-size: 18px;
  line-height: 1.5;
  padding: 10px 10px;
  font-family: "HyundaiRegular";
  font-weight: normal;
  
}
.locationtimebox h4 {
  color: #002c5f;
  font-size: 19px;
  line-height: 1.5;
  margin: 15px 0 8px;
  font-family: "HyundaiRegular";
  font-weight: 500;
}

.gallerybox {
  position: relative;
  padding-bottom: 40px;
  overflow: hidden;
}

.gallerybox .container-fluid.p-0 {
  margin-right: -105px;
}

.gallerybox .galleryimg {
  margin: 0 10px;
  padding: 0;
}

.gallerytextbox {
  position: relative;
  text-align: left;
  padding: 0 0 10px 5px;
}

.gallerytextbox h2 {
  color: #000;
  font-size: 19px;
  line-height: 1.5;
  font-family: "HyundaiLight";
  font-weight: bold;
}

.gallerytextbox h3 {
  color: #012b5c;
  font-size: 32px;
  line-height: 1.5;
  font-family: "HyundaiLight";
  font-weight: bold;
}

.happeningbox .videotag {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.outershadow {
  width: 100%;
  position: relative;
}

.outershadow figure {
  background-size: 100% 100% !important;
  background-repeat: no-repeat;
  height: 100%;
  min-height: 450px;
  background-position: center;
}

.exp_pointers {
  list-style-type: none;
  margin-left: 30px;
  ;
}

.social-link {
  text-decoration: underline !important;
  color: inherit;
}

.outershadow figure.Leftthumb,
.outershadow figure.Rightthumb {
  background-size: cover !important;
}

.outershadow figure.Leftthumb {
  background-position: left !important;
}

.outershadow figure.Rightthumb {
  background-position: right !important;
}

.outershadow figure.imgcover {
  background-position: cover !important;
}

.outershadow .RightText,
.outershadow .LeftText {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 40px;
  background: #fff;
  z-index: 99;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.outershadow .RightText::before,
.outershadow .RightText::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  opacity: 30%;
}

.outershadow .RightText::before {
  -webkit-box-shadow: 1px 10px 20px 0 #a5a5a5;
  box-shadow: 1px 10px 20px 0 #a5a5a5;
  z-index: -1;
}

.outershadow .RightText::after {
  -webkit-box-shadow: 0 50px 50px 0 #a5a5a5;
  box-shadow: 0 50px 50px 0 #a5a5a5;
  z-index: -2;
}

.outershadow .RightText h3,
.outershadow .LeftText h3 {
  position: relative;
  font-size: 30px;
  color: #002c5f;
  line-height: 36px;
  font-family: "HyundaiRegular";
  font-weight: 500;
  font-style: normal;
  margin-bottom: 10px;
}

.outershadow .RightText p,
.outershadow .LeftText p {
  font-size: 16px;
  line-height: 29px;
  margin-bottom: 15px;
}

.outershadow .LeftText::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0 0 50px 0 #a5a5a5;
  box-shadow: 0 0 50px 0 #a5a5a5;
  z-index: -1;
  opacity: 30%;
}

.outershadow .listStyle {
  margin: 0;
  padding: 0;
  list-style: none;
}

.outershadow .listStyle li {
  position: relative;
  line-height: 24px;
  font-size: 16px;
  margin: 0;
  padding-left: 24px;
  list-style: none;
  margin-bottom: 8px;
}

.outershadow .listStyle li::before {
  position: absolute;
  top: 6px;
  left: 0;
  font: 14px/1 FontAwesome;
  content: "\f192";
  color: #002c5f;
}

.loginformbox .form-group {
  position: relative;
}

.loginformbox .form-group .form-control,
.loginformbox .form-group .form-control-plaintext,
.loginformbox .form-group .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}

.loginformbox .form-group label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "HyundaiRegular";
  font-weight: 500;
  pointer-events: none;
  border: 1px solid transparent;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transition: opacity 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
  -webkit-transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  -moz-transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  -ms-transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  -o-transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .loginformbox .form-group label {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
    -moz-transition: none;
    -ms-transition: none;
  }
}

.loginformbox .form-group .form-control,
.loginformbox .form-group .form-control-plaintext {
  padding: 1rem 0.75rem;
}

.loginformbox .form-group .form-control-plaintext::-moz-placeholder,
.loginformbox .form-group .form-control::-moz-placeholder {
  color: transparent;
}

.loginformbox .form-group .form-control-plaintext::-webkit-input-placeholder,
.loginformbox .form-group .form-control::-webkit-input-placeholder {
  color: transparent;
}

.loginformbox .form-group .form-control-plaintext::-moz-placeholder,
.loginformbox .form-group .form-control::-moz-placeholder {
  color: transparent;
}

.loginformbox .form-group .form-control-plaintext:-ms-input-placeholder,
.loginformbox .form-group .form-control:-ms-input-placeholder {
  color: transparent;
}

.loginformbox .form-group .form-control-plaintext::-ms-input-placeholder,
.loginformbox .form-group .form-control::-ms-input-placeholder {
  color: transparent;
}

.loginformbox .form-group .form-control-plaintext::-webkit-input-placeholder,
.loginformbox .form-group .form-control::-webkit-input-placeholder {
  color: transparent;
}

.loginformbox .form-group .form-control-plaintext::-moz-placeholder,
.loginformbox .form-group .form-control::-moz-placeholder {
  color: transparent;
}

.loginformbox .form-group .form-control-plaintext:-ms-input-placeholder,
.loginformbox .form-group .form-control:-ms-input-placeholder {
  color: transparent;
}

.loginformbox .form-group .form-control-plaintext::-ms-input-placeholder,
.loginformbox .form-group .form-control::-ms-input-placeholder {
  color: transparent;
}

.loginformbox .form-group .form-control-plaintext::-webkit-input-placeholder,
.loginformbox .form-group .form-control::-webkit-input-placeholder {
  color: transparent;
}

.loginformbox .form-group .form-control-plaintext::-moz-placeholder,
.loginformbox .form-group .form-control::-moz-placeholder {
  color: transparent;
}

.loginformbox .form-group .form-control-plaintext:-ms-input-placeholder,
.loginformbox .form-group .form-control:-ms-input-placeholder {
  color: transparent;
}

.loginformbox .form-group .form-control-plaintext::-ms-input-placeholder,
.loginformbox .form-group .form-control::-ms-input-placeholder {
  color: transparent;
}

.loginformbox .form-group .form-control-plaintext::-webkit-input-placeholder,
.loginformbox .form-group .form-control::-webkit-input-placeholder {
  color: transparent;
}

.loginformbox .form-group .form-control-plaintext::-moz-placeholder,
.loginformbox .form-group .form-control::-moz-placeholder {
  color: transparent;
}

.loginformbox .form-group .form-control-plaintext:-ms-input-placeholder,
.loginformbox .form-group .form-control:-ms-input-placeholder {
  color: transparent;
}

.loginformbox .form-group .form-control-plaintext::-ms-input-placeholder,
.loginformbox .form-group .form-control::-ms-input-placeholder {
  color: transparent;
}

.loginformbox .form-group .form-control-plaintext::-webkit-input-placeholder,
.loginformbox .form-group .form-control::-webkit-input-placeholder {
  color: transparent;
}

.loginformbox .form-group .form-control-plaintext::-moz-placeholder,
.loginformbox .form-group .form-control::-moz-placeholder {
  color: transparent;
}

.loginformbox .form-group .form-control-plaintext:-ms-input-placeholder,
.loginformbox .form-group .form-control:-ms-input-placeholder {
  color: transparent;
}

.loginformbox .form-group .form-control-plaintext::-ms-input-placeholder,
.loginformbox .form-group .form-control::-ms-input-placeholder {
  color: transparent;
}

.loginformbox .form-group .form-control-plaintext::placeholder,
.loginformbox .form-group .form-control::placeholder {
  color: transparent;
}

.loginformbox .form-group .form-control-plaintext:not(:-moz-placeholder-shown),
.loginformbox .form-group .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.loginformbox .form-group .form-control-plaintext:not(:-moz-placeholder-shown),
.loginformbox .form-group .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.825rem;
  padding-bottom: 0.625rem;
}

.loginformbox .form-group .form-control-plaintext:not(:-ms-input-placeholder),
.loginformbox .form-group .form-control:not(:-ms-input-placeholder) {
  padding-top: 1.825rem;
  padding-bottom: 0.625rem;
}

.loginformbox .form-group .form-control-plaintext:not(:-moz-placeholder-shown),
.loginformbox .form-group .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.825rem;
  padding-bottom: 0.625rem;
}

.loginformbox .form-group .form-control-plaintext:not(:-ms-input-placeholder),
.loginformbox .form-group .form-control:not(:-ms-input-placeholder) {
  padding-top: 1.825rem;
  padding-bottom: 0.625rem;
}

.loginformbox .form-group .form-control-plaintext:not(:-moz-placeholder-shown),
.loginformbox .form-group .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.825rem;
  padding-bottom: 0.625rem;
}

.loginformbox .form-group .form-control-plaintext:not(:-ms-input-placeholder),
.loginformbox .form-group .form-control:not(:-ms-input-placeholder) {
  padding-top: 1.825rem;
  padding-bottom: 0.625rem;
}

.loginformbox .form-group .form-control-plaintext:not(:-moz-placeholder-shown),
.loginformbox .form-group .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.825rem;
  padding-bottom: 0.625rem;
}

.loginformbox .form-group .form-control-plaintext:not(:-ms-input-placeholder),
.loginformbox .form-group .form-control:not(:-ms-input-placeholder) {
  padding-top: 1.825rem;
  padding-bottom: 0.625rem;
}

.loginformbox .form-group .form-control-plaintext:not(:-moz-placeholder-shown),
.loginformbox .form-group .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.825rem;
  padding-bottom: 0.625rem;
}

.loginformbox .form-group .form-control-plaintext:not(:-ms-input-placeholder),
.loginformbox .form-group .form-control:not(:-ms-input-placeholder) {
  padding-top: 1.825rem;
  padding-bottom: 0.625rem;
}

.loginformbox .form-group .form-control-plaintext:focus,
.loginformbox .form-group .form-control-plaintext:not(:placeholder-shown),
.loginformbox .form-group .form-control:focus,
.loginformbox .form-group .form-control:not(:placeholder-shown) {
  padding-top: 1.825rem;
  padding-bottom: 0.625rem;
}

.loginformbox .form-group .form-control-plaintext:-webkit-autofill,
.loginformbox .form-group .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.loginformbox .form-group .form-control:not(:-moz-placeholder-shown)~label {
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  -moz-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  -ms-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  -o-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.loginformbox .form-group .form-control:not(:-ms-input-placeholder)~label {
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  -moz-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  -ms-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  -o-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.loginformbox .form-group .form-control-plaintext~label,
.loginformbox .form-group .form-control:focus~label,
.loginformbox .form-group .form-control:not(:placeholder-shown)~label,
.loginformbox .form-group .form-select~label {
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  -moz-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  -ms-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  -o-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.loginformbox .form-group .form-control:not(:-moz-placeholder-shown)~label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: #fff;
  border-radius: 0.375rem;
  -webkit-border-radius: 0.375rem;
  -moz-border-radius: 0.375rem;
  -ms-border-radius: 0.375rem;
  -o-border-radius: 0.375rem;
}

.loginformbox .form-group .form-control:not(:-moz-placeholder-shown)~label::after,
.loginformbox .form-group .form-control-plaintext~label::after,
.loginformbox .form-group .form-control:focus~label::after,
.loginformbox .form-group .form-control:not(:placeholder-shown)~label::after,
.loginformbox .form-group .form-select~label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: #fff;
  border-radius: 0.375rem;
  -webkit-border-radius: 0.375rem;
  -moz-border-radius: 0.375rem;
  -ms-border-radius: 0.375rem;
  -o-border-radius: 0.375rem;
}

.loginformbox .form-group .form-control:-webkit-autofill~label {
  color: #000;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  -moz-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  -ms-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  -o-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.loginformbox .form-group .form-control-plaintext~label {
  border-width: var(--bs-border-width) 0;
  color: rgba(33, 37, 41, 0.65);
}

.loginformbox .form-group .form-control:disabled~label,
.loginformbox .form-group :disabled~label {
  color: #6c757d;
}

.loginformbox .form-group .form-control:disabled~label::after,
.loginformbox .form-group :disabled~label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.loginformbox .form-group .form-control,
.loginformbox .form-group .form-floating,
.loginformbox .form-group .form-select {
  position: relative;
  width: 100%;
}

.loginformbox .form-group .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-check-input {
  width: 1.1rem;
  height: 1.1rem;
  margin-top: 0.19em;
}

.devider-wraps {
  height: 0.5px;
  width: 100%;
  border: 1px dashed #e0e8ed;
  border-radius: 50px;
}

.devider-text {
  position: absolute;
  left: 50%;
  top: 50%;
  color: #333;
  font-size: 14px;
  font-family: "HyundaiRegular";
  font-weight: 500;
  transform: translate(-50%, -50%);
  background: #ffffff;
  padding: 0 10px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.social-login {
  list-style: none;
  margin: 0;
  padding: 0;
}

.social-login li {
  list-style: none;
}

.social-login li .socialform {
  width: 60px;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 32px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 0.375rem;
  border: #dee2e6 solid 1px;
  border-style: dashed !important;
  -webkit-border-radius: 0.375rem;
  -moz-border-radius: 0.375rem;
  -ms-border-radius: 0.375rem;
  -o-border-radius: 0.375rem;
}

.text-primary-hover {
  color: #2d3033;
  font-size: 16px;
  font-family: "HyundaiLight";
  font-weight: normal;
}

.formheading {
  margin-bottom: 30px;
}

.formheading h2 {
  color: #002b5f;
  font-weight: 400;
  position: relative;
  font-size: 20px;
  line-height: 1.5;
}

.formheading h2 span {
  border-bottom: #002b5f solid 2px;
}

.formheading h2 span a,
.formheading h2 strong a {
  color: #002b5f;
  font-size: 22px;
  line-height: 1.5;
  font-weight: 500;
}

.loginformbox .form-group input.form-control[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
}

.loginformbox .form-group input.form-control[type="number"]::-webkit-inner-spin-button,
.loginformbox .form-group input.form-control[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

.formcontrollerbx .form-group .form-control {
  border-color: #002b5f;
  height: 65px;
}

.formcontrollerbx .form-group textarea.form-control {
  height: auto !important;
}

.formcontrollerbx .form-group .foraddrss {
  top: 8px;
}

.formcontrollerbx .formgroup {
  display: block;
  background: transparent;
  margin-top: 10px;
  width: 100%;
  text-align: center;
}

.formcontrollerbx .formgroup .btn-upload {
  position: relative;
  color: #002b5f;
  font-size: 18px;
  line-height: 1.5;
  cursor: pointer;
  margin: 0;
  font-weight: 500;
}

.formcontrollerbx .formgroup .btn-upload input[type="file"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  outline: none;
  cursor: pointer;
}

.formcontrollerbx .checkboxmain {
  position: relative;
  display: block;
  padding-top: 8px;
  padding-left: 1.25rem;
}

.formcontrollerbx .checkboxmain .form-check-label {
  margin-left: 5px;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 400;
}

.formcontrollerbx .genderheading {
  color: #333;
  font-size: 20px;
  margin-bottom: 6px;
}

.formcontrollerbx .checkboxmain.colorchng label.form-check-label {
  color: #002b5f;
  font-size: 18px;
  line-height: 1.5;
  font-family: "HyundaiRegular";
  font-weight: 400;
}

.formcontrollerbx .widthincres {
  min-width: 14rem;
  font-size: 22px;
  padding: 10px 15px;
}

.RightText .imgnumbering,
.LeftText .imgnumbering {
  width: 19%;
  height: auto;
  max-width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 32px;
}

.testimonialBG {
  /* border-bottom: #fff solid 10px; */
  background: #f4f4f4;
}

.carouseltextimonial {
  position: relative;
  max-width: 100%;
  width: 100%;
  margin: 0 auto 0;
  overflow: hidden;
}

/* .carouseltextimonial #testimonialbx .carousel-inner .control-arrow {
  width: 5%;
} */

.carouseltextimonial #testimonialbx .carousel-inner .control-arrow {
  font-size: 30px;
  line-height: 1.5;
  background-image: none !important;
  background-color: rgba(85, 85, 85, 0.9);
  border-radius: 50px;
  transition: 0.3s;
  color: rgba(255, 255, 255, 0.6);
  width: 54px;
  height: 54px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  top: 33%;
  transform: translateY(33%);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  -webkit-transform: translateY(33%);
  -moz-transform: translateY(33%);
  -ms-transform: translateY(33%);
  -o-transform: translateY(33%);
}

.carouseltextimonial #testimonialbx .carousel-inner .control-arrow::before {
  margin: 0;
  display: inline-block;
  border-top: none;
  border-right: none !important;
  border-left: none;
  border-bottom: none;
}

.carouseltextimonial #testimonialbx .carousel-inner .control-arrow::before {
  content: "\f054";
  padding-left: 5px;
  font-family: FontAwesome;
}

.carouseltextimonial #testimonialbx .carousel-inner .control-arrow.control-prev::before {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.carouseltextimonial .carousel-inner {
  width: 82%;
  border-radius: 5px;
  margin: 0 auto !important;
  -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  padding: 15px 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.carouseltextimonial .carousel-inner .carousel-item {
  height: auto;
}

.carouseltextimonial .testimonial-item p .fa-quote-left {
  color: #002c5f;
  margin-right: 6px;
}

.carouseltextimonial .testimonial-item p .fa-quote-right {
  /* color: #002c5f; */
  color: #bcbcbc;
  margin-left: 6px;
}

.carouseltextimonial .testimonial-item .testithumbnail {
  width: 100px;
  height: 100px;
  border: #77777741 solid 4px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.carouseltextimonial .testimonial-item .testithumbnail .testithumb {
  max-width: 100%;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.olcontentbx ol li {
  margin-bottom: 15px;
}

.olcontentbx ol li,
.olcontentbx ol li p {
  font-size: 17px;
}

.olcontentbx ol li ol {
  margin-left: 25px;
  list-style-type: lower-alpha;
}

.olcontentbx ol li ol li {
  margin-bottom: 10px;
}

.olcontentbx ol li ol ol {
  list-style-type: lower-roman;
}

.forcontentbx h3 {
  color: #000;
  font-size: 1.225rem;
  line-height: 1.5;
  margin-bottom: 10px;
}

.forcontentbx p a,
.forcontentbx p .fa {
  color: #002c5f;
}

.forcontentbx p a:hover {
  text-decoration: underline !important;
}

.faqsbx .accordion .accordion-item {
  margin-bottom: 15px;
  border-radius: 0.2rem !important;
  overflow: hidden;
  border: #dee2e6 solid 1px !important;
  -webkit-border-radius: 0.2rem !important;
  -moz-border-radius: 0.2rem !important;
  -ms-border-radius: 0.2rem !important;
  -o-border-radius: 0.2rem !important;
}

.faqsbx .accordion .accordion-item .accordion-header {
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent !important;
}

.faqsbx .accordion .accordion-item .accordion-button {
  display: block;
  width: 100%;
  padding: 1rem 1.25rem;
  text-align: left;
  outline: none !important;
  font-weight: 500;
  color: #002c5f;
  font-size: 17px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #eff3f6;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  -webkit-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-radius 0.3s ease, -webkit-box-shadow 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-radius 0.3s ease, -webkit-box-shadow 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, border-radius 0.3s ease;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, border-radius 0.3s ease, -webkit-box-shadow 0.3s ease-in-out;
  -webkit-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, border-radius 0.3s ease;
  -moz-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, border-radius 0.3s ease;
  -ms-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, border-radius 0.3s ease;
  -o-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, border-radius 0.3s ease;
}

.faqsbx .accordion .accordion-item .accordion-button:not(.collapsed) {
  color: #002c5f;
  background-color: rgba(0, 44, 95, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.faqsbx .accordion .accordion-item .accordion-button::after,
.faqsbx .accordion .accordion-item .accordion-button:not(.collapsed)::after {
  color: #002c5f;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 900;
  width: 28px;
  height: 28px;
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: FontAwesome;
  background-image: none;
  border-radius: 50%;
  transition: all ease 0.5s;
  background-color: rgba(0, 44, 95, 0.1);
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  -ms-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.faqsbx .accordion .accordion-item .accordion-button::after {
  content: "\f0d7";
}

.faqsbx .accordion .accordion-item .accordion-button:not(.collapsed)::after {
  content: "\f0d8";
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.faqsbx .accordion .accordion-item .accordion-body p {
  font-size: 16px;
  margin: 5px 10px 5px 10px;
}

.faqsbx .accordion .accordion-item .accordion-body ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  margin-bottom: 8px;
  text-align: left;
}

.faqsbx .accordion .accordion-item .accordion-body ul li {
  position: relative;
  list-style: none;
  padding: 5px 0 5px 20px;
  font-size: 16px;
  margin: 0 10px 0 10px;
  line-height: 21px;
  color: #000;
}

.faqsbx .accordion .accordion-item .accordion-body ul li::before {
  position: absolute;
  top: 8px;
  left: 0;
  font: 14px/1 FontAwesome;
  content: "\f192";
  color: #002c5f;
}

.site-list {
  padding: 0;
  list-style: none;
  margin-bottom: 30px;
}

.site-list li {
  color: #002c5f;
  font-size: 20px;
  line-height: 30px;
  font-family: "HyundaiLight";
}

.site-list li::after {
  content: "";
  top: 10px;
  left: 0px;
  width: 20px;
  height: 2px;
  background-color: #002c5f;
}

.img-colarge2 {
  position: relative;
  height: 100%;
  margin-bottom: 20px;
  margin: 0 0 0 30px;
  z-index: 1;
}

.img-colarge2 img {
  border-style: none;
  height: auto;
  max-width: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
  position: absolute;
  left: 0;
  top: 0;
  -o-object-fit: cover;
  object-fit: cover;
  vertical-align: middle;
}

.colarge-2 {
  position: relative;
  height: 100%;
  width: 100%;
}

.square-80 {
  width: 80px;
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.square-80 .h1 {
  font-size: 2.5rem;
  line-height: 1.5;
  margin-bottom: 0;
}

.profilebx {
  background: #f1f5f8;
  padding: 80px 0 80px;
}

.profilebx .rounded-2 {
  border-radius: 0.375rem;
  -webkit-border-radius: 0.375rem;
  -moz-border-radius: 0.375rem;
  -ms-border-radius: 0.375rem;
  -o-border-radius: 0.375rem;
}

.profilebx .card-top {
  background-color: #002c5f;
}

.profilebx .card-top {
  border-radius: 0.375rem 0.375rem 0 0;
  -webkit-border-radius: 0.375rem 0.375rem 0 0;
  -moz-border-radius: 0.375rem 0.375rem 0 0;
  -ms-border-radius: 0.375rem 0.375rem 0 0;
  -o-border-radius: 0.375rem 0.375rem 0 0;
}

.profilebx .square-40 {
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(245, 245, 245, 0.1);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.profilebx .imgborder img {
  border: 5px solid rgba(245, 245, 245, 0.4);
  border-radius: 50%;
  width: 122px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.profilebx .crd-capser h5 {
  font-size: 20px;
  line-height: 28px;
}

.profilebx .square-50 {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.profilebx .crdapproval-caps h5 {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  line-height: 1.5;
}

.profilebx .crdapproval-caps p {
  color: #979797;
  font-size: 14px;
}

.profilebx .cardinfo {
  position: relative;
  border: none;
  overflow: hidden;
  border-radius: 0.375rem;
  -webkit-border-radius: 0.375rem;
  -moz-border-radius: 0.375rem;
  -ms-border-radius: 0.375rem;
  -o-border-radius: 0.375rem;
}

.profilebx .cardinfo .card-header {
  background: #ffffff;
  padding: 0.8rem 1rem;
  border-color: #e2e8ec;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.profilebx .cardinfo .card-header h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.profilebx .cardinfo .card-body {
  padding: 1.25rem 0 1.25rem;
}

.profilebx .cardinfo .card-body ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.profilebx .cardinfo .card-body ul li {
  position: relative;
  margin-bottom: 16px;
}

.profilebx .cardinfo .card-body ul li h4 {
  color: #000;
  font-size: 16px;
  line-height: 1.8;
}

.profilebx .cardinfo .card-body ul li p {
  color: #adb5bd;
  font-size: 14px;
  line-height: 1.5;
}

.carouseltextimonial .carousel-inner,
.carouseltextimonial .carousel-inner .carousel.carousel-slider {
  overflow: inherit;
}

.carouseltextimonial #testimonialbx .carousel-inner .control-arrow.control-prev {
  left: -110px;
}

.carouseltextimonial #testimonialbx .carousel-inner .control-arrow.control-next {
  right: -110px;
}

.carousel.carouselmain .containerbox .control-arrow {
  font-size: 30px;
  line-height: 0;
  background-image: none !important;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  transition: 0.3s;
  color: rgba(255, 255, 255, 0.6);
  width: 54px;
  height: 54px;
  top: 42%;
  margin: 0 .75rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  transform: translateY(42%);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  -webkit-transform: translateY(42%);
  -moz-transform: translateY(42%);
  -ms-transform: translateY(42%);
  -o-transform: translateY(42%);
}

.carousel.carouselmain .containerbox .control-arrow:focus,
.carousel.carouselmain .containerbox .control-arrow:hover,
.carousel.carouselmain .containerbox .control-arrow:focus,
.carousel.carouselmain .containerbox .control-arrow:hover {
  text-decoration: none;
  outline: 0;
  opacity: .9;
}

.carousel.carouselmain .containerbox .control-arrow::before {
  margin: 0;
  display: inline-block;
  border-top: none;
  border-right: none !important;
  border-left: none;
  border-bottom: none;
  color: #fff;
  content: "\f054";
  padding-left: 5px;
  font-family: FontAwesome;
}

.carousel.carouselmain .containerbox .control-arrow.control-prev::before {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.carousel.carouselmain .containerbox .control-dots li {
  width: 30px !important;
  height: 3px !important;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.carouseltextimonial .carousel .slider-wrapper {
  margin-bottom: 5px;
}

.carouseltextimonial .carousel .slider-wrapper ul.slider {
  margin-bottom: 10px;
}

.carouseltextimonial .carousel .control-dots {
  margin: 0;
  bottom: -10px;
}

.countsliderbx .carousel-root .carousel {
  overflow: inherit;
  padding-bottom: 1rem;
}

.countsliderbx .carousel-root .carousel .control-dots {
  margin: 0;
  bottom: -10px;
  padding-bottom: 10px;
}

.carouseltextimonial .carousel .control-dots li,
.countsliderbx .carousel-root .carousel .control-dots li {
  height: 8px !important;
  width: 8px !important;
  font-size: 0;
  border-radius: 0;
  margin: 0 5px;
  overflow: hidden;
  white-space: nowrap;
  background: #afafaf;
  -webkit-box-shadow: none;
  box-shadow: none;
  transition: 0.25s ease-in;
  -webkit-transition: 0.25s ease-in;
  -moz-transition: 0.25s ease-in;
  -ms-transition: 0.25s ease-in;
  -o-transition: 0.25s ease-in;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.carouseltextimonial .carousel .control-dots li.selected,
.countsliderbx .carousel-root .carousel .control-dots li.selected {
  background: rgb(0, 44, 95);
  width: 10px !important;
  height: 10px !important;
}

.countsliderbx .carousel-root .carousel .control-arrow {
  font-size: 28px;
  line-height: 1.5;
  background-image: none !important;
  background-color: rgba(85, 85, 85, 0.9);
  border-radius: 50px;
  transition: 0.3s;
  opacity: .8;
  color: rgba(255, 255, 255, 0.9);
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  top: 33%;
  transform: translateY(33%);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  -webkit-transform: translateY(33%);
  -moz-transform: translateY(33%);
  -ms-transform: translateY(33%);
  -o-transform: translateY(33%);
}

.countsliderbx .carousel-root .carousel .control-arrow::before {
  margin: 0;
  display: inline-block;
  border-top: none;
  border-right: none !important;
  border-left: none;
  border-bottom: none;
  content: "\f054";
  padding-left: 5px;
  font-family: FontAwesome;
}

.countsliderbx .carousel-root .carousel .control-arrow.control-prev::before {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.countsliderbx .carousel-root .carousel .control-arrow.control-prev {
  left: -1.625rem;
}

.countsliderbx .carousel-root .carousel .control-arrow.control-next {
  right: -1.625rem;
}

.loginformbox.mtgap50 {
  margin-top: 4.425rem !important;
}

.faqsbx .accordion .accordion-item .accordion-body div {
  color: #000;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  font-family: "HyundaiLight";
  margin-bottom: 10px;
}

.forcontentbx p {
  font-size: 17px;
  line-height: 1.5;
}

.modal-dialog.modal-md .modal-body .close {
  padding: 0 10px;
  margin: 0;
  float: right;
  position: absolute;
  top: 8px;
  right: 0;
  color: #000;
  font-size: 2.225rem;
  opacity: .8;
  font-weight: 700;
  line-height: 1;
  z-index: 2;
  display: block;
  transition: opacity .3s;
  background-color: transparent;
  border: 0;
  text-shadow: 0 1px 0 #fff;
  -webkit-transition: opacity .3s;
  -moz-transition: opacity .3s;
  -ms-transition: opacity .3s;
  -o-transition: opacity .3s;
}

.modal-dialog.modal-md .modal-body .close:hover {
  opacity: .75;
}

.carouselmain .containerbox .carousel-item video {
  object-position: top;
}

/* .carouselmain .slide-inner .countdownbox {
  height: auto;
  align-items: flex-start;
}

.carouselmain .slide-inner .countdownbox ul li,
.carouselmain .slide-inner .countdownbox ul li span {
  color: #FFF;
}

.carouselmain .slide-inner .countdownbox ul {
  border: #ccc solid 1px;
  padding: 0;
}

.carouselmain .slide-inner .countdownbox ul li {
  border-right: #ccc solid 1px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.carouselmain .slide-inner .countdownbox ul li:last-child {
  border-right: 0;
} */

.carouselmain .containerbox .carousel-indicators {
  display: none !important;
}

.carouselmain .containerbox .countdownband {
  padding: 0 0 10px;
  /* background: rgba(0, 0, 0, .6);
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0; */
  z-index: 1;
}

.carouselmain .containerbox .countdownband .countdownbox ul {
  padding: 0;
}

.carouselmain .containerbox .countdownband .countdownbox ul li,
.carouselmain .containerbox .countdownband .countdownbox ul li span {
  color: #ebebeb;
}

.carouselmain .containerbox .countdownband .countdownbox ul li {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  padding: 0;
  padding-right: 18px;
  /* border-right: 1px solid #FFF; */
  /* padding: 2px 20px; */
}

.carouselmain .containerbox .countdownband .countdownbox ul li span {
  font-size: 34px;
  line-height: 1.5;
  font-weight: 500;
  margin-bottom: 10px;
  border-bottom: #ccc solid 1px;
}

.carouselmain .containerbox .countdownband .countdownbox ul li:last-child {
  border-right: none;
  padding-right: 0;
}

.happeningbox .overlaycontent .happenbtn-main {
  width: 100%;
  height: 360px;
  display: flex;
  justify-content: flex-end;
}

.happeningbox .overlaycontent .happenbtn {
  color: #fff;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-family: "HyundaiRegular";
}

.happeningbox .overlaycontent .happenbtn .fa {
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.happeningbox .overlaycontent .happenbtn:hover .fa {
  transform: translateX(3px);
  -moz-transform: translateX(3px);
  -ms-transform: translateX(3px);
  -o-transform: translateX(3px);
  -webkit-transform: translateX(3px);
}

.happeningbox .overlaycontent .bottombx {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
}

.happeningbox .overlaycontent .bottombx .width80 {
  /* width: 76%; */
  width: 100%;
}

.happeningbox .overlaycontent .bottombx h3 {
  color: #FFF;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  margin-bottom: 0;
}

.happeningbox .overlaycontent .bottombx p {
  color: #e8e8e8;
  font-size: 16px;
  line-height: 26px;
  margin: 10px 0 5px;
}

.happeningbox .overlaycontent .bottombx h3 .fa,
.happeningbox .overlaycontent .bottombx p .fa {
  margin-right: 4px;
}

.happeningbox .overlaycontent .bottombx .btnbookover {
  color: #002c5f !important;
  padding: 2px 10px;
  background: #FFF;
  border-color: #FFF !important;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.happeningbox .overlaycontent .bottombx .btnbookover:hover {
  color: #002c5f !important;
  background: #e8e8e8;
  border-color: #e8e8e8 !important;
}

.happeningbox .overlaycontent .bottombx .btnbookover .fa {
  margin-left: 4px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.happeningbox .overlaycontent .bottombx .btnbookover:hover .fa {
  margin-left: 6px;
}

.extraarrowbox {
  position: relative;
  text-align: center;
  padding: 20px 0 6px;
}

.extraarrowbox .l-arrow-circle,
.extraarrowbox .r-arrow-circle {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin: 0 1%;
  border-radius: 50%;
  box-sizing: border-box;
  border: 3px solid #01244d;
  transition: 0.3s;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.extraarrowbox .l-arrow-circle::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 35%;
  height: 35%;
  border-left: 4px solid #01244d;
  border-bottom: 4px solid #01244d;
  -webkit-transform: translate(-30%, -50%) rotate(45deg);
  transform: translate(-30%, -50%) rotate(45deg);
  -moz-transform: translate(-30%, -50%) rotate(45deg);
  -ms-transform: translate(-30%, -50%) rotate(45deg);
  -o-transform: translate(-30%, -50%) rotate(45deg);
}

.extraarrowbox .r-arrow-circle::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 35%;
  height: 35%;
  border-right: 4px solid #01244d;
  border-bottom: 4px solid #01244d;
  -webkit-transform: translate(-70%, -50%) rotate(-45deg);
  transform: translate(-70%, -50%) rotate(-45deg);
  -moz-transform: translate(-70%, -50%) rotate(-45deg);
  -ms-transform: translate(-70%, -50%) rotate(-45deg);
  -o-transform: translate(-70%, -50%) rotate(-45deg);
}

.extraarrowbox .l-arrow-circle:hover,
.extraarrowbox .r-arrow-circle:hover {
  border-color: #01244d;
  background-color: #01244d;
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.extraarrowbox .l-arrow-circle:hover::before {
  border-left: 4px solid #FFFFFF;
  border-bottom: 4px solid #FFFFFF;
}

.extraarrowbox .r-arrow-circle:hover::before {
  border-right: 4px solid #FFFFFF;
  border-bottom: 4px solid #FFFFFF;
}

.boxsocial {
  font-family: "HyundaiRegular";
  text-align: center;
  overflow: hidden;
  position: relative;
}

.boxsocial:before {
  content: '';
  background: #fff;
  width: 100%;
  height: 100%;
  transform: scaleY(0);
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0.80;
  transition: all 0.3s ease-in-out;
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.boxsocial:hover:before {
  transform: scaleY(1);
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
}

.boxsocial img {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: clip;
}

.boxsocial .box-content {
  color: #1f3766;
  width: 100%;
  padding: 0 20px;
  opacity: 0;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  transition: all 0.6s ease;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
}

.boxsocial:hover .box-content {
  opacity: 1;
}

.boxsocial .title {
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
  text-transform: capitalize;
  font-family: HyundaiRegular;
  margin-bottom: 15px;
}

.boxsocial .post {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
  display: inline-block;
}

.boxsocial .icon {
  padding: 0;
  margin: 0;
  margin-top: 20px;
  list-style: none;
}

.boxsocial .icon li {
  display: inline-block;
  margin: 0 3px;
}

.boxsocial .icon li a {
  color: #fff;
  background: #1f3766;
  font-size: 18px;
  line-height: 1.5;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}

.boxsocial .icon li a:hover {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

@media only screen and (max-width:990px) {
  .boxsocial {
    margin: 0 0 30px;
  }
}

.testimonials_item {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
}

.testimonials_item .card-testimonial_profile {
  width: 100%;
  flex-shrink: 0;
  height: 100%;
  border-radius: 0;
  transform: translateX(0);
  overflow: hidden;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.testimonials_item .card-testimonial_profile .card-testimonial_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.testimonials_item .card-testimonial_description {
  position: relative;
  padding: 6px 18px 0 22px;
}

.testimonials_item .card-testimonial_description h3,
.testimonials_item .card-testimonial_description h4 {
  margin: 0;
  margin-bottom: 12px;
}

.testimonials_item .card-testimonial_description p {
  text-align: left;
}

.testimonialBG .carouseltextimonial .carousel-control-prev,
.testimonialBG .carouseltextimonial .carousel-control-next {
  left: inherit;
  top: inherit;
  right: 40px;
  bottom: 10px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin: 0 1%;
  z-index: 1;
  opacity: 1;
  border-radius: 50%;
  background: #FFF;
  box-sizing: border-box;
  border: 3px solid #01244d;
  transition: 0.3s;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.testimonialBG .carouseltextimonial .carousel-control-prev .carousel-control-prev-icon,
.testimonialBG .carouseltextimonial .carousel-control-next .carousel-control-next-icon {
  background-image: none !important;
  color: #01244d;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
}

.testimonialBG .carouseltextimonial .carousel-control-prev {
  /*right: 10rem;
  */
  left: 68%;
  transform: translateX(68%);
  -webkit-transform: translateX(68%);
  -moz-transform: translateX(68%);
  -ms-transform: translateX(68%);
  -o-transform: translateX(68%);
}

.testimonialBG .carouseltextimonial .carousel-control-next {
  /*right: 6.625rem;*/
  right: 20%;
  transform: translateX(20%);
  -webkit-transform: translateX(20%);
  -moz-transform: translateX(20%);
  -ms-transform: translateX(20%);
  -o-transform: translateX(20%);
}

.testimonialBG .carouseltextimonial .carousel-control-prev:hover,
.testimonialBG .carouseltextimonial .carousel-control-next:hover {
  border-color: #002c5f;
  background: #002c5f;
}

.testimonialBG .carouseltextimonial .carousel-control-prev:hover span,
.testimonialBG .carouseltextimonial .carousel-control-next:hover span {
  color: #FFFFFF;
}

.testimonialBG .carouseltextimonial .viewmorebtn {
  border-radius: 0;
  padding: 8px 20px;
  font-size: 16px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.testimonialBG .carouseltextimonial .viewmorebtn .fa {
  margin-left: 4px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.testimonialBG .carouseltextimonial .viewmorebtn:hover .fa {
  margin-left: 6px;
}

.testimonialBG .carouseltextimonial .carousel-indicators {
  margin: 0;
  display: block;
  list-style: none;
  position: absolute;
  top: 50%;
  right: calc(16px * 1);
  bottom: auto !important;
  left: inherit !important;
  width: 10px !important;
  text-align: center;
  z-index: 21;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.testimonialBG .carouseltextimonial .carousel-indicators li {
  display: block;
  width: 11px;
  height: 11px;
  opacity: 0.2;
  border-radius: 11px;
  background: #4e4a67;
  transition: all 0.3s;
  border: none;
  margin: 6px 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-border-radius: 11px;
  -moz-border-radius: 11px;
  -ms-border-radius: 11px;
  -o-border-radius: 11px;
}

.testimonialBG .carouseltextimonial .carousel-indicators li.active {
  height: calc(11px * 3);
  opacity: 1;
  background: #01244d;
}

.testimonialform .form-control {
  height: 45px;
  border-color: #002b5f;
  border-radius: 0;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.testimonialform .btnsearch {
  box-shadow: none;
  height: 45px;
  width: 100%;
}

.testimonialInner-box {
  position: relative;
  border-radius: 0;
  padding: 0;
  padding-bottom: 25px;
  background-color: #fff;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.testimonialInner-box div {
  width: 100%;
}

.testimonialInner-box .image {
  position: relative;
  border-radius: 0;
  overflow: hidden;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  height: 250px;
  background: #000;
  -o-object-fit: fill;
  object-fit: fill;
}

.testimonialInner-box .image::before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  display: block;
  content: '';
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, .2);
  border-radius: 0%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-border-radius: 0%;
  -moz-border-radius: 0%;
  -ms-border-radius: 0%;
  -o-border-radius: 0%;
}

.testimonialInner-box .image img {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: block;
  max-height: 100%;
  -o-object-fit: fill;
  object-fit: fill;
}

.testimonialInner-box .lower-content {
  position: relative;
  padding: 10px 15px;
}

.testimonialInner-box .post-info {
  position: relative;
  top: -25px;
  border-radius: 5px;
  padding: 0;
  margin: 0 10px;
  background-color: #002c5f;
  font-family: nekst;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  width: auto;
  font-weight: 400;
  display: inline-block;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.testimonialInner-box .post-info li {
  position: relative;
  display: inline-block;
  font-size: 15px;
  color: #fff;
  min-width: 80px;
  text-align: center;
  line-height: 20px;
  font-weight: 300;
  font-family: "HyundaiLight";
  vertical-align: middle;
  padding: 6px 20px 5px;
}

.testimonialInner-box h4 {
  color: #002c5f;
  font-size: 20px;
  line-height: 1.4;
  font-weight: 500;
  margin-bottom: 15px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.testimonialInner-box h4 a {
  color: #002c5f;
}

.testimonialInner-box p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.testimonialInner-box .buttonbox {
  width: 100%;
  display: block;
  text-align: center;
  padding: 0 15px;
}

.testimonialInner-box .buttonbox .btn-style {
  text-align: center;
  display: block;
  padding: 6px 10px;
  border-radius: 50px;
  color: #797979;
  font-size: 15px;
  line-height: 1.5;
  font-weight: 600;
  background: #f1f1f1;
  transition: .3s;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -ms-transition: .3s;
  -o-transition: .3s;
}

@-webkit-keyframes circle {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

@keyframes circle {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

.innerheading {
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
}

.innerheading h2 {
  color: #000000;
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  position: relative;
  padding: 0 10px 10px;
  line-height: 40px;
  display: inline-block;
}

.innerheading h2::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 3px;
  background: #1f3766;
  margin: 0;
}

.innergallerybx .eventsmainbx figcaption.gallerybox h2 {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 10px;
}

.innergallerybx .eventsmainbx figcaption.gallerybox .gallerybtn {
  font-size: 16px;
}

.innergallerybx .eventsmainbx figcaption.gallerybox .gallerybtn span {
  padding: 0 12px 5px;
  border-bottom: #002c5f solid 2px;
}

.formcontrollerbx .checkboxmain.colorchng label.form-check-label a {
  color: #002b5f;
}

.galleryformbox {
  position: relative;
  width: 100%;
  /* max-height: 68%; */
  max-height: 780px;
  height: 100%;
}

.galleryformbox .bgfigure {
  position: relative;
  margin: 0;
  height: 100%;
  width: 100%;
}

.galleryformbox figure.bgfigure::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.2);
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .65) 89%, rgba(0, 0, 0, .65) 96%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .65) 89%, rgba(0, 0, 0, .65) 96%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .65) 89%, rgba(0, 0, 0, .65) 96%);
}

.galleryformbox .bgfigure img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.galleryformbox .loginformbox {
  /* position: absolute;
  top: 0;
  left: 0; */
  z-index: 2;
  background: rgba(255, 255, 255, 0.5);
  margin: 1rem;
}

.galleryformbox .formcontrollerbx .form-group .form-control {
  height: 55px;
  padding: 25px 10px 10px;
}

.galleryformbox .loginformbox .form-group label,
.galleryformbox .formcontrollerbx .checkboxmain.colorchng label.form-check-label {
  font-size: 15px;
}

.galleryformbox .loginformbox .formcontrollerbx p {
  font-size: 15px;
  line-height: 25px;
  margin-bottom: 10px;
}

.galleryformbox .formcontrollerbx .form-group select.form-control {
  height: 55px;
  padding: 0 4px;
}

.testimonialBG .carouseltextimonial .carousel-inner {
  width: 100%;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.navbar.menu-bar.fixed-top {
  padding: 20px 0 20px;
}

.testimonialInner-box p .fa-quote-left,
.testimonialInner-box p .fa-quote-right {
  color: #bcbcbc;
  font-size: 1.215rem;
}

.testimonialInner-box p .fa-quote-left {
  margin-right: 4px;
}

.testimonialInner-box p .fa-quote-right {
  margin-left: 4px;
}

.tabpillsbox .eventsmainbx figure {
  height: 330px;
}

.innergallerybx .col-sm-6.col-lg-4 .eventsmainbx figure {
  height: 270px;
  min-height: 270px;
}

.innergallerybx .col-sm-6.col-lg-6 .eventsmainbx figure {
  height: 330px;
}

.innergallerybx .tabpillsbox.mt-0 .nav-pills.mb-5 {
  margin-bottom: 2.425rem !important;
}

.footermain .footersocial .facebookicon,
.footermain .footersocial .youtubeicon {
  width: 20px;
  height: 20px;
}

.adventuremainbx .happeningbox {
  height: 335px;
}

.adventuremainbx .happeningbox img {
  min-height: 100%;
}

.welcom-section .site-list li::after {
  top: 14px;
}

.testimonialInner-box .readmore {
  color: #002c5f;
  font-weight: 600;
  display: inline-block;
  font-family: "HyundaiLight";
}

.galleryformbox.galleryverticalform .loginformbox {
  background: #FFF;
  position: relative;
}

.adventurebx .col-sm-4.col-lg-4 .eventsmainbx figure {
  height: 265px;
}

.adventurebx .eventsmainbx figcaption .knowmorebtn {
  width: calc(100% - 65%);
  font-size: 15px;
  margin-left: auto;
  text-align: right;
}

.adventurebx .eventsmainbx figcaption h2 {
  font-size: 16px;
  line-height: 24px;
}

.justify-content-center .col-sm-6.col-md-5.col-lg-6 {
  margin-bottom: 1.5rem !important;
}

.col-sm-6.col-md-5.col-lg-6 .testimonialInner-box .image {
  height: 350px;
}

.innergallerybx .galleryformbox .loginformbox {
  margin-top: 0;
  margin-bottom: 1rem;
}







@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .navbar.menu-bar .container.p-0 {
    padding: 0 15px !important;
  }

  .navbar.menu-bar .navbar-toggler {
    position: relative;
    border: #002c5f solid 1px;
  }

  .navbar.menu-bar .navbar-toggler .navbar-toggler-icon::after {
    content: "\f0c9";
    position: absolute;
    top: 6px;
    left: 15px;
    color: #002c5f;
    font-size: 1.625rem;
    font-weight: 900;
    font-family: "Font Awesome 6 Free";
  }

  .navbar.menu-bar .navbar-collapse .navbar-nav {
    padding-top: 5px;
  }

  .menu-bar .navbar-nav .nav-item {
    line-height: 1.5;
  }

  p {
    line-height: 1.5;
    font-size: 17px;
    font-weight: 300;
  }

  ol li {
    font-size: 17px;
    line-height: 1.5;
    margin-bottom: 6px;
  }

  .secTitle {
    width: 100%;
  }

  .secTitle h2 {
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
  }

  .site-list {
    margin-bottom: 15px;
  }

  .site-list li {
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    padding-left: 30px;
  }

  .img-colarge2 {
    margin: 10px 0 10px 0;
  }

  .carouselmain .containerbox {
    max-width: 100%;
  }

  .carouselmain .carousel-item,
  .carouselmain .carousel-item>img {
    height: 55vh;
    height: 400px;
  }

  .gallerybox .container-fluid.p-0 {
    margin-right: 0;
  }

  .gallerybox .galleryimg {
    margin: 0 10px;
    margin-bottom: 15px;
  }

  .homefixbtn {
    padding: 5px 15px;
    bottom: 2rem;
    height: 45px;
    width: 45px;
  }

  .homefixbtn .fa,
  .homefixbtn .fa-solid {
    font-size: 18px;
  }

  .footermain .footerlink a {
    margin-right: 22px;
    margin-bottom: 5px;
  }

  .footermain .footerlink a::before {
    right: -12px;
    height: 15px;
    width: 1px;
    top: 0px;
  }

  .enqiryfooter {
    margin-bottom: 20px;
  }

  .footermain .footerlink {
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 15px;
  }

  .footermain .enqiryfooter span {
    font-size: 13px;
  }

  .enqiryfooter.mt-2.text-left {
    text-align: center !important;
  }

  .copyright.text-sm-right.mt-2 {
    margin-top: 16px !important;
    text-align: center !important;
  }

  .footermain .footersocial svg {
    height: 25px;
    width: 25px;
    margin-right: 15px;
  }

  .innerbannerHeader {
    padding-top: 3.906rem;
    width: 100%;
    min-height: 415px;
  }

  .innerbannerHeader .img-fliuid {
    min-height: 430px;
    height: 100%;
  }

  .innerbannerHeader h1 {
    font-size: 28px;
    line-height: 38px;
  }

  .innerbannerHeader .bannercontainer .bannerTitle h2,
  .innerbannerHeader h2 {
    font-size: 22px;
    line-height: 50px;
    margin-top: 0px;
  }

  .outershadow .row.m-0 {
    margin-bottom: 20px !important;
  }

  .outershadow .row.m-0 .col-sm-6.m-0.p-0 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .outershadow .row.m-0 .col-sm-6.m-0.p-0.order-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .outershadow .row.m-0 .col-sm-6.m-0.p-0.order-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .outershadow .RightText,
  .outershadow .LeftText {
    width: 100%;
    height: 100%;
    padding: 20px 16px 20px
  }

  .outershadow .RightText h3,
  .outershadow .LeftText h3 {
    font-size: 26px;
    line-height: 34px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .outershadow .RightText p,
  .outershadow .LeftText p {
    font-size: 17px;
    line-height: 28px;
    margin-bottom: 15px;
  }

  .outershadow .RightText h5,
  .outershadow .LeftText h5 {
    line-height: 1.4;
  }

  .eventsmainbx figcaption {
    height: 100%;
    padding: 10px 0 10px;
  }

  .loginformbox h1.h2 {
    font-size: 1.625rem;
  }

  .forcontentbx h3 {
    font-size: 1.225rem;
    line-height: 1.4;
  }

  .forcontentbx h3 strong {
    font-weight: 500;
  }

  .loginformbox .form .form-check-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: .3125rem;
    margin-left: 0;
    width: 1rem;
    height: 1rem;
    top: -2px;
  }

  .olcontentbx ol li {
    margin-left: 20px;
  }

  .olcontentbx ol li,
  .olcontentbx ol li p {
    font-size: 17px;
    line-height: 1.6;
  }

  .olcontentbx ol li ol li {
    margin-bottom: 10px;
    margin-left: 5px;
  }

  .olcontentbx ol li ol {
    margin-left: 15px;
  }

  .loginformbox .square-80 {
    width: 74px;
    height: 74px;
  }

  .loginformbox .square-80 .h1 {
    font-size: 2rem;
    line-height: 1.5;
  }

  .eventsmainbx figcaption.gallerybox {
    padding-bottom: 40px;
    height: auto;
  }

  .eventsmainbx figcaption.gallerybox h2 {
    font-size: 20px;
    font-weight: 500;
  }

  .eventsmainbx figcaption.gallerybox .gallerybtn {
    font-size: 17px;
    font-weight: 400;
  }

  .eventsmainbx figcaption.gallerybox .gallerybtn span {
    padding: 0 10px 8px;
    border-bottom: #002c5f solid 2px;
  }

  .eventsmainbx figcaption p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }

  .eventsmainbx figcaption .datebx {
    font-size: 14px;
    line-height: 1.5;
    padding: 8px 10px 6px;
    margin-bottom: 14px;
    margin-top: 4px;
  }

  .formcontrollerbx .checkboxmain.colorchng label.form-check-label {
    font-size: 16px;
    line-height: 1.5;
  }

  .formheading h2 {
    font-size: 18px;
    line-height: 1.5;
  }

  .formheading h2 span a,
  .formheading h2 strong a {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 500;
  }

  .formheading h2 strong a {
    display: block;
  }

  .formcontrollerbx .checkboxmain.mr-sm-5 {
    margin-right: 15px !important;
  }

  .formcontrollerbx .formgroup .btn-upload {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
  }

  .loginformbox .formcontrollerbx.p-4 {
    padding: 1rem !important;
  }

  .btnheader {
    font-size: 16px;
    line-height: 1.5;
    padding: 5px 15px 5px;
  }

  .menu-bar.navbar-light .navbar-toggler:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  /* .menu-bar.navbar-light .navbar-brand img {
    width: 80%;
  } */

  .navigationcontroller .container {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navigationcontroller .navbar-toggler {
    margin-left: 12px;
  }

  .navigationcontroller .navbar-collapse {
    width: 100%;
    height: 70vh;
    max-width: 100%;
    position: absolute;
    top: 3.425rem;
    left: 0;
    padding-top: 1.125rem;
    border-top: #cecece solid 1px;
    background: #FFF;
  }

  .navigationcontroller.menu-bar .nav-item {
    margin-bottom: 15px;
  }

  .carousel.carouselmain.slide .containerbox .carousel .control-arrow {
    display: none !important;
  }

  .carousel.carouselmain .containerbox .control-arrow {
    font-size: 22px;
    width: 36px;
    height: 36px;
    top: inherit;
    bottom: 2rem;
    margin: 0 .75rem;
  }

  .carousel.carouselmain .containerbox .control-dots li {
    height: 2px !important;
  }

  .carouselmain .slide-inner {
    padding-right: 1rem;
    padding-left: 1rem;
    overflow: hidden;
  }

  .carousel.carouselmain .control-next.control-arrow {
    right: inherit !important;
    left: 45px;
  }

  .carouselmain .slide-inner h1 {
    font-size: 28px;
    line-height: 38px;
  }

  .carouselmain .slide-inner h2 {
    font-size: 22px;
    line-height: 36px;
    margin: -10px 0 -4px;
  }

  .carouselmain .slide-inner p {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .carouselmain .containerbox .btn-primary {
    min-width: 145px;
    font-size: 16px;
    padding: 4px 15px;
  }

  .carouselmain .containerbox .countdownband .countdownbox ul li {
    font-size: 14px;
    padding: 0 16px 0 0;
  }

  .carouselmain .carousel-caption.text-left.slide-inner .text-left p {
    margin-bottom: 10px;
  }

  .happeningbox .overlaycontent h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5;
    font-family: HyundaiRegular;
  }

  .countsliderbx .carousel-root .carousel .control-arrow {
    font-size: 22px;
    width: 36px;
    height: 36px;
    top: inherit;
    bottom: -38px;
    margin: 0 .75rem;
  }

  .countsliderbx .carousel-root .carousel .control-arrow.control-prev {
    left: 33% !important;
  }

  .countsliderbx .carousel-root .carousel .control-arrow.control-next {
    right: inherit !important;
    left: 50% !important;
  }

  .countsliderbx.pt-3.pb-4 {
    padding-bottom: 4rem !important;
  }

  .countdownbox h2 {
    font-size: 32px;
    line-height: 1.3;
    margin-bottom: 15px;
  }

  .countdownbox ul li {
    padding: 0 16px;
    font-size: 18px;
    line-height: 1.2;
    font-weight: 600;
  }

  .countdownbox li span {
    font-size: 34px;
    line-height: 36px;
    margin-bottom: 4px;
  }

  .adventurebox p {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 4px;
  }

  .tabpillsbox .nav-pills .nav-link {
    font-size: 15px;
    line-height: 1.5;
    font-weight: 500;
    padding: 10px 16px;
    min-width: auto;
  }

  .tabpillsbox .nav-pills .nav-item {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .tabpillsbox .nav.nav-pills.mb-5.nav-pills {
    margin-bottom: 1.425rem !important;
  }

  .eventsmainbx figcaption h2 {
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 6px;
  }

  .eventsmainbx figcaption .d-sm-flex.justify-content-sm-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .carouseltextimonial .carousel-inner {
    width: 100%;
  }

  .testimonial-item h3 {
    font-size: 20px;
  }

  .carouseltextimonial .testimonial-item .d-sm-flex.flex-column.align-items-center.justify-content-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .carouseltextimonial #testimonialbx .carousel-inner .control-arrow {
    font-size: 22px;
    width: 36px;
    height: 36px;
    top: -12px;
    bottom: inherit;
  }

  .carouseltextimonial #testimonialbx .carousel-inner .control-arrow.control-prev {
    left: 0;
  }

  .carouseltextimonial #testimonialbx .carousel-inner .control-arrow.control-next {
    right: 0;
  }

  .innerbannerHeader .img-fliuid,
  .innerbannerHeader .img-fluid {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .innerbannerHeader .bannercontainer .bannerTitle h2 {
    font-size: 22px;
    line-height: 36;
    font-weight: 500;
    margin-top: -10px;
  }

  .eventsmainbx figcaption .btn-primary {
    font-size: 16px;
  }

  .bread-crumb .breadcrumbbox {
    padding: 15px 8px 15px;
    font-size: 15px;
    border-bottom: #034591 solid 1px;
  }

  .bread-crumb .col-12.col-sm {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    border-right: #034591 solid 1px;
  }

  .locationtimebox {
    border: #034591 solid 2px;
    margin-bottom: 20px;
    padding: 0;
  }

  .locationtimebox h4 {
    font-size: 17px;
    line-height: 1.5;
    margin: 15px 0 8px;
    font-weight: 500;
  }

  .locationtimebox .btn-new {
    font-size: 16px;
    line-height: 1.5;
    padding: 10px 10px;
  }

  .faqsbx .accordion .accordion-item .accordion-button {
    padding: 1rem 1.325rem 1rem 1.25rem;
    font-size: 16px;
  }

  .faqsbx .accordion .accordion-item .accordion-body {
    padding: 1rem;
  }

  .faqsbx .accordion .accordion-item .accordion-body ul li {
    padding: 5px 0 5px 20px;
    font-size: 16px;
    line-height: 22px;
  }

  .faqsbx .accordion .accordion-item .accordion-body p {
    font-size: 16px;
    margin: 5px 10px 5px 10px;
  }

  .loginformbox.mtgap50 {
    margin-top: 4rem !important;
  }

  .olcontentbx ol {
    padding-left: 0;
  }

  .happeningbox.img-fluid-height {
    height: auto;
  }

  .footermain .footersocial {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .footermain .footerlogo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
  }

  .img-colarge2 img {
    position: inherit;
  }

  .carouselmain .slide-inner h3 {
    font-size: 21px;
    line-height: 32px;
    margin: 2px 0 0.5rem;
  }

  .carouselmain .containerbox .carousel-control-next,
  .carouselmain .containerbox .carousel-control-prev {
    width: auto;
    top: inherit;
    bottom: 16px;
  }

  .carouselmain .containerbox .carousel-control-next {
    right: 38%;
  }

  .carouselmain .containerbox .carousel-control-prev {
    left: 38%;
  }

  .carouselmain .carousel-control-next .carousel-control-next-icon,
  .carouselmain .carousel-control-prev .carousel-control-prev-icon {
    width: 42px;
    height: 42px;
    font-size: 20px;
  }

  .testimonials_item .card-testimonial_description {
    padding-bottom: 3.825rem;
  }

  .testimonialBG .carouseltextimonial .carousel-control-prev {
    left: 35%;
    transform: translateX(35%);
    -webkit-transform: translateX(35%);
    -moz-transform: translateX(35%);
    -ms-transform: translateX(35%);
    -o-transform: translateX(35%);
  }

  .testimonialBG .carouseltextimonial .carousel-control-next {
    right: 38%;
    transform: translateX(38%);
    -webkit-transform: translateX(38%);
    -moz-transform: translateX(38%);
    -ms-transform: translateX(38%);
    -o-transform: translateX(38%);
  }

}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .menu-bar .navbar-nav .nav-item {
    margin-right: 5px;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navigationcontroller .container {
    max-width: 100%;
  }

  .navigationcontroller .btnheader {
    font-size: 16px;
    line-height: 1.5;
    padding: 6px 15px 6px;
    margin-left: 6px;
  }

  .navigationcontroller .navbar-brand img {
    width: 90%;
  }

  .navigationcontroller.menu-bar .nav-item .nav-link {
    font-size: 16px;
  }

  .carouselmain .slide-inner h1 {
    font-size: 40px;
    line-height: 54px;
    font-weight: 500;
  }

  .carouselmain .slide-inner h2 {
    font-size: 30px;
    line-height: 46px;
    font-weight: 500;
    margin: -10px 0 -6px;
  }

  .carouselmain .slide-inner p {
    font-size: 22px;
    line-height: 1.5;
  }

  .carouselmain .containerbox .btn-primary {
    min-width: 175px;
    font-size: 18px;
  }

  .carousel.carouselmain .containerbox .control-arrow {
    font-size: 26px;
    line-height: 0;
    width: 50px;
    height: 50px;
    top: 42%;
    margin: 0 .75rem;
  }

  .secTitle h2 {
    font-size: 28px;
    font-weight: 500;
    padding-bottom: 10px;
    line-height: 38px;
  }

  .secTitle h2::before {
    width: 30%;
    height: 3px;
  }

  p {
    font-size: 17px;
    line-height: 1.5;
    font-weight: 300;
  }

  .blueband .h3 {
    margin-top: 6px;
    font-size: 26px;
    line-height: 30px;
  }

  .blueband .h5 {
    font-size: 16px;
  }

  .happeningbox .overlaycontent h2 {
    font-size: 26px;
    line-height: 1.5;
  }

  .countdownbox h2 {
    font-size: 28px;
    line-height: 1.3;
    margin-bottom: 8px;
  }

  .countdownbox ul {
    padding: 6px 0 6px;
  }

  .countdownbox ul li {
    padding: 0 16px;
    font-size: 18px;
    line-height: 1.2;
    font-weight: 600;
  }

  .countdownbox li span {
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 4px;
  }

  .adventurebox {
    margin-top: 10px;
    padding: 15px 10px 10px;
  }

  .adventurebox p {
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 0;
  }

  .countsliderbx .carousel-root .carousel .control-arrow {
    font-size: 24px;
    line-height: 1.5;
    width: 45px;
    height: 45px;
    top: 20%;
    transform: translateY(20%);
    -webkit-transform: translateY(20%);
    -moz-transform: translateY(20%);
    -ms-transform: translateY(20%);
    -o-transform: translateY(20%);
  }

  .happeningbox.img-fluid-height {
    height: 210px;
  }

  .tabpillsbox .nav-pills .nav-link {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    padding: 10px 15px;
    min-width: 150px;
  }

  .tabpillsbox .tab-content .col-sm-4,
  .tabpillsbox .tab-pane .col-sm-4.mb-4,
  .adventurebx .tabpillsbox .tab-pane .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .eventsmainbx figcaption h2 {
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    margin-bottom: 6px;
  }

  .eventsmainbx figcaption .knowmorebtn {
    font-size: 14px;
    line-height: 26px;
    font-weight: 400;
  }

  .eventsmainbx figcaption p .fa {
    margin-right: 5px;
  }

  .eventsmainbx figcaption .btn-primary {
    padding: 5px 12px 5px;
    font-size: 16px;
    line-height: 1.5;
  }

  .carouseltextimonial #testimonialbx .carousel-inner .control-arrow {
    font-size: 26px;
    line-height: 1.5;
    width: 50px;
    height: 50px;
    top: 36%;
    transform: translateY(36%);
    -webkit-transform: translateY(36%);
    -moz-transform: translateY(36%);
    -ms-transform: translateY(36%);
    -o-transform: translateY(36%);
  }

  .carouseltextimonial #testimonialbx .carousel-inner .control-arrow.control-prev {
    left: -78px;
  }

  .carouseltextimonial #testimonialbx .carousel-inner .control-arrow.control-next {
    right: -78px;
  }

  .carouseltextimonial .carousel-inner {
    width: 80%;
    padding: 15px 20px;
  }

  .eventsmainbx figcaption.gallerybox h2 {
    font-size: 20PX;
  }

  .eventsmainbx figcaption.gallerybox .gallerybtn {
    font-size: 17px;
    font-weight: 500;
  }

  .eventsmainbx figcaption.gallerybox .gallerybtn span {
    padding: 4px 10px 10px;
    border-bottom: #002c5f solid 2px;
  }

  .eventsmainbx figcaption.gallerybox .gallerybtn:hover span {
    padding-left: 1.425rem;
    padding-right: 1.425rem;
  }

  .footermain .footerlink a {
    margin-right: 15px;
    margin-bottom: 5px;
  }

  .footermain .footerlink a::before {
    right: -8px;
    height: 15px;
    width: 1px;
    top: 1px;
  }

  .homefixbtn {
    padding: 5px 15px;
    bottom: 3rem;
    height: 46px;
    width: 46px;
  }

  .homefixbtn .fa,
  .homefixbtn .fa-solid {
    font-size: 20px;
  }

  .innerbannerHeader .img-fliuid,
  .innerbannerHeader .img-fluid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .innerbannerHeader h1 {
    font-size: 40px;
    line-height: 54px;
    font-weight: 500;
    /* text-shadow: 2px 2px 8px #0a0a0a73; */
  }

  .innerbannerHeader .bannercontainer .bannerTitle h2 {
    font-size: 30px;
    line-height: 46px;
    font-weight: 500;
    margin-top: -10px;
  }

  .bread-crumb .breadcrumbbox {
    padding: 12px 10px 12px;
    font-size: 16px;
    line-height: 26px;
    border-right: #e5e5e5 solid 1px;
  }

  .locationtimebox .btn-new {
    font-size: 16px;
    line-height: 1.4;
    padding: 10px 8px;
  }

  .col-xl-3.mx-auto.text-center {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .locationtimebox h4 {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    margin: 10px 0 8px;
  }

  ol li,
  ul li {
    font-size: 17px;
    line-height: 1.5;
    font-weight: 300;
    margin-bottom: 6px;
  }

  .outershadow .col-sm-6.m-0 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .outershadow .col-sm-6.m-0+.col-sm-6.m-0 {
    margin-bottom: 30px !important;
  }

  .outershadow .RightText h3,
  .outershadow .LeftText h3 {
    font-size: 28px;
    line-height: 34px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .outershadow .RightText h4,
  .outershadow .LeftText h4 {
    font-size: 18px;
  }

  .outershadow .RightText p,
  .outershadow .LeftText p {
    font-size: 17px;
    line-height: 30px;
    margin-bottom: 12px;
  }

  .site-list li {
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    padding-left: 28px;
  }

  .site-list li::after {
    top: 10px;
    left: 0px;
    width: 18px;
    height: 2px;
    background-color: #002c5f;
  }

  .loginformbox.mtgap50 .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .loginformbox.mtgap50 .bg-mode.shadow .p-3.p-sm-4.p-md-5 {
    padding: 2rem !important;
  }

  .olcontentbx ol li ol {
    margin-left: 2px;
  }

  .forcontentbx h3 {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 10px;
  }

  .img-colarge2 {
    margin: 0;
  }

  .img-colarge2 img {
    position: inherit;
    height: 350px;
  }

  .carouselmain .slide-inner h3 {
    font-size: 24px;
    line-height: 36px;
    margin: 2px 0 0.5rem;
  }

  .pt-5.mt-5.pb-5 .container.pt-3 .col-sm-4.mb-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-4.mb-4 .eventsmainbx figure {
    height: 225px;
    min-height: 225px;
  }

  .eventsmainbx figcaption {
    padding: 10px 0;
  }

  .eventsmainbx figcaption .datebx {
    font-size: 14px;
    padding: 8px 8px 6px;
  }

  .eventsmainbx figcaption .datebx .fa {
    font-size: 14px;
    margin-right: 6px;
  }

  .carouselmain .containerbox .carousel-control-next,
  .carouselmain .containerbox .carousel-control-prev {
    width: 10%;
  }

  .carouselmain .carousel-control-next .carousel-control-next-icon,
  .carouselmain .carousel-control-prev .carousel-control-prev-icon {
    width: 45px;
    height: 45px;
    font-size: 20px;
  }

  .testimonialBG .carouseltextimonial .carousel-control-prev {
    left: 79%;
    transform: translateX(79%);
    -webkit-transform: translateX(79%);
    -moz-transform: translateX(79%);
    -ms-transform: translateX(79%);
    -o-transform: translateX(79%);
  }

  .testimonialBG .carouseltextimonial .carousel-control-next {
    right: 2%;
    transform: translateX(2%);
    -webkit-transform: translateX(2%);
    -moz-transform: translateX(2%);
    -ms-transform: translateX(2%);
    -o-transform: translateX(2%);
  }

  .happeningbox .overlaycontent .bottombx h3 {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
  }

}


@media only screen and (min-width:1024px) and (max-width:1279px) {
  .secTitle h2::before {
    width: 30%;
    height: 3px;
  }

  .forcontentbx h3 {
    font-size: 22px;
  }

  .homefixbtn {
    padding: 5px 15px;
    bottom: 3rem;
    height: 45px;
    width: 45px;
  }

  .eventsmainbx figcaption h2 {
    font-size: 16px;
    line-height: 26px;
  }

  .eventsmainbx figcaption .knowmorebtn {
    font-size: 14px;
    line-height: 26px;
  }

  .eventsmainbx figcaption.gallerybox h2 {
    margin-top: 6px;
  }

  .tabpillsbox .nav-pills .nav-link {
    padding: 10px 15px;
    min-width: 150px;
  }

  .innerbannerHeader .img-fliuid,
  .innerbannerHeader .img-fluid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .innerbannerHeader h1 {
    font-size: 40px;
    line-height: 54px;
  }

  .innerbannerHeader .bannercontainer .bannerTitle h2 {
    font-size: 30px;
    line-height: 46px;
    margin-top: -10px;
  }

  .carousel.carouselmain .containerbox .control-arrow {
    font-size: 26px;
    line-height: 0;
    width: 50px;
    height: 50px;
    top: 42%;
    margin: 0 .75rem;
  }

  .carouselmain .slide-inner h1 {
    font-size: 40px;
    line-height: 54px;
  }

  .carouselmain .slide-inner h2 {
    font-size: 30px;
    line-height: 46px;
    margin: -10px 0 -6px;
  }

  .carouselmain .slide-inner p {
    font-size: 20px;
  }

  p {
    font-size: 17px;
  }

  .blueband .h3 {
    font-size: 26px;
    line-height: 30px;
    margin: 6px 0 6px;
  }

  .happeningbox .overlaycontent h2 {
    font-size: 26px;
    line-height: 1.5;
  }

  .navigationcontroller .container {
    max-width: 100%;
  }

  .site-list li::after {
    top: 11px;
  }

  .loginformbox.mtgap50 .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .bread-crumb .breadcrumbbox {
    padding: 14px 6px 14px;
    font-size: 18px;
    line-height: 26px;
    border-right: #e5e5e5 solid 1px;
  }

  .outershadow .col-sm-6.m-0 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .outershadow .RightText h3,
  .outershadow .LeftText h3 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 10px;
  }

  .outershadow .RightText p,
  .outershadow .LeftText p {
    font-size: 17px;
    line-height: 30px;
    margin-bottom: 12px;
  }

  .locationtimebox h4 {
    font-size: 15px;
    line-height: 22px;
    margin: 10px 0 8px;
  }

  .col-xl-3.mx-auto.text-center {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .navigationcontroller.menu-bar .nav-item .nav-link {
    font-size: 17px;
  }

  .outershadow .col-sm-6.m-0+.col-sm-6.m-0 {
    margin-bottom: 30px !important;
  }

  .outershadow .RightText,
  .outershadow .LeftText {
    padding: 20px 25px;
  }

  .olcontentbx ol li ol {
    margin-left: 0;
  }

  .forcontentbx h3 strong {
    font-weight: 500;
  }

  .img-colarge2 {
    margin: 0 0 0 15px;
  }

  .img-colarge2 img {
    position: absolute;
    height: 100%;
  }

  .carouselmain .slide-inner h3 {
    font-size: 24px;
    line-height: 36px;
    margin: 2px 0 0.5rem;
  }

  .carouselmain .containerbox .carousel-control-next,
  .carouselmain .containerbox .carousel-control-prev {
    width: 8%;
  }

  .carouselmain .carousel-control-next .carousel-control-next-icon,
  .carouselmain .carousel-control-prev .carousel-control-prev-icon {
    width: 45px;
    height: 45px;
    font-size: 20px;
  }

  .testimonialBG .carouseltextimonial .carousel-control-prev {
    left: 82%;
    transform: translateX(82%);
    -webkit-transform: translateX(82%);
    -moz-transform: translateX(82%);
    -ms-transform: translateX(82%);
    -o-transform: translateX(82%);
  }

  .testimonialBG .carouseltextimonial .carousel-control-next {
    right: 2%;
    transform: translateX(2%);
    -webkit-transform: translateX(2%);
    -moz-transform: translateX(2%);
    -ms-transform: translateX(2%);
    -o-transform: translateX(2%);
  }


}


@media only screen and (min-width:1280px) and (max-width:1365px) {
  p {
    font-size: 18px;
    line-height: 1.5;
    font-weight: 300;
  }

  .tabpillsbox .nav-pills .nav-link {
    font-size: 18px;
    line-height: 1.5;
    font-weight: 500;
    padding: 10px 20px;
    min-width: 160px;
  }

  .faqsbx .accordion .accordion-item .accordion-body div {
    font-size: 18px;
    line-height: 1.5;
    font-weight: 300;
    margin-bottom: 10px;
  }

  .faqsbx .accordion .accordion-item .accordion-button {
    font-size: 18px;
    font-weight: 500;
  }

  .innerbannerHeader h1 {
    font-size: 44px;
    line-height: 56px;
    font-weight: 500;
  }

  .innerbannerHeader .bannercontainer .bannerTitle h2 {
    font-size: 32px;
    line-height: 48px;
    font-weight: 500;
  }

  .carouselmain .slide-inner h1 {
    font-size: 44px;
    line-height: 56px;
    font-weight: 500;
  }

  .carouselmain .slide-inner p {
    font-size: 26px;
    font-weight: bold;
    line-height: 1.4;
  }

  .countdownbox h2 {
    font-size: 38px;
    line-height: 1.3;
    margin-bottom: 15px;
  }

  .countdownbox li span {
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 4px;
  }

  .site-list li {
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
    padding-left: 30px;
  }

  .img-colarge2 {
    margin: 0px 0 0 15px;
  }

  .carouselmain .slide-inner h3 {
    font-size: 24px;
    line-height: 36px;
    margin: 2px 0 0.5rem;
  }


}




/* end */